import { RiShoppingCartLine } from "react-icons/ri"
import { useCart } from "../../contexts/useCart"
import Link from "next/link"

export function Cart() {
  const { cart } = useCart()
  return (
    <Link href="/carrinho">
      <div className="flex items-center gap-2 cursor-pointer">
        <div className="w-9 h-9 md:w-11 md:h-11 rounded-full flex items-center justify-center bg-[#EF511B] text-white">
          <RiShoppingCartLine className="text-2xl" />
        </div>

        <span className="text-xl font-bold text-[#EF511B]">{cart?.length}</span>
      </div>
    </Link>
  )
}
